<div class="flex min-w-0 flex-auto flex-col">
	<!-- Main -->
	<div class="flex-auto p-6 pt-0.5">
		<div class="mt-4 flex flex-col items-center space-y-1 text-center sm:mt-0 sm:items-start sm:pr-8 sm:text-left">
			<div class="m-6 text-xl font-medium">
				{{ 'notifications.title' | transloco: { count: notificationsCount } }}
			</div>
		</div>

		<div
			mat-dialog-actions
			class="mt-6 flex flex-shrink-0 flex-row-reverse items-center sm:ml-4 sm:mt-0"
		>
			<button
				mat-flat-button
				class="ml-2"
				color="primary"
				(click)="navigate()"
			>
				{{ 'notifications.submit' | transloco }}
			</button>
			<button
				mat-stroked-button
				class="mr-auto"
				(click)="cancel()"
			>
				{{ 'notifications.cancel' | transloco }}
			</button>
		</div>
	</div>
</div>
