import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PayorRoutes } from 'app/common/routes';
import { catchAppError } from 'app/common/utils/utils.error';

@UntilDestroy()
@Component({
	selector:      'app-notification-action-selector',
	templateUrl:   './notification-action-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone:    true,
	imports:       [TranslocoModule, MatDialogModule, MatButtonModule]
})
export class NotificationActionSelectorComponent {
	public notificationsCount: number;

	constructor(
		private readonly router: Router,
		@Inject(MAT_DIALOG_DATA) public data: number,
		public dialogRef: MatDialogRef<NotificationActionSelectorComponent>
	) {
		this.notificationsCount = data;
	}

	public navigate(): void {
		this.router
			.navigate([PayorRoutes.Root])
			.then(() => {
				this.dialogRef.close();
			})
			.catch(catchAppError);
	}

	public cancel(): void {
		this.dialogRef.close();
	}
}
