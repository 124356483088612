export enum CommonApiRoutes {
	Search = 'search',
	Export = 'export',
	Import = 'import',
	Options = 'options',
	RecentOptions = 'recent-options',
	Exist = 'exist',
	Excluded = 'excluded',
	Execute = 'execute',
	File = 'file',
	Resolve = 'resolve',
	ResolveAll = 'resolve-all',
	BulkUpdate = 'bulk',
	CheckDuplicates = 'duplicates/check',
	CheckDuplicatesByName = 'duplicates/check-by-name',
	History = 'history'
}

export enum UserApiRoutes {
	Root = 'users',
	Delete = 'delete',
	CheckDuplicates = 'duplicates/check-by-email'
}

export enum RoleApiRoutes {
	Root = 'roles',
	Permissions = 'permissions'
}

export enum NdcApiRoutes {
	Root = 'ndc',
	Lookup = 'lookup',
	NdcDrugName = 'ndc-drug-name',
	IsNdcMedispanExists = 'is-ndc-medispan-exist'
}

export enum NdcListApiRoutes {
	Root = 'ndc/list',
	ImportFile = 'import',
	ConnectedInstances = 'connected-instances'
}

export enum DrugClassApiRoutes {
	Root = 'drug-class',
	DrugClassLookup = 'lookup'
}

export enum DrugClassListApiRoutes {
	Root = 'drug-class/list'
}

export enum CustomerApiRoutes {
	Root = 'customer',
	CheckDuplicatesByHierarchy1 = 'duplicates/check-by-hierarchy1',
	CheckDuplicatesByHierarchy2 = 'duplicates/check-by-hierarchy2',
	CheckCreatedLocationSubtypes = 'check-created-location-subtypes',
	CheckCreatedLocationTypes = 'check-created-location-types',
	ImportLocations = 'import-locations',
	DownstreamClientIdOptions = 'downstream-client-id/options',
	FileMappingConfig = 'file-mapping-config',
	Export = 'export-locations'
}

export enum CustomerRetentionApiRoutes {
	Root = 'customer/retention-rate',
	RebateTotalRanges = 'rebate-total-ranges'
}

export enum CustomerNoteApiRoutes {
	Root = 'customer/note',
	Restore = 'restore'
}

export enum CustomerMonthlyDataApiRoutes {
	Root = 'statistics',
	Customer = 'customer',
	Location = 'location',
	ScriptCount = 'script-count',
	Monthly = 'monthly',
	TotalWacPrices = 'total-wac',
	Transaction = 'transaction',
	Drugs = 'drugs',
	Submission = 'submission',
	FillDates = 'fill-dates',
	MarkAsReady = 'ready',

	CurrentPeriod = 'current-period',
	RecentPeriods = 'recent-periods',

	FilterLevels = 'filter-levels'
}

export enum FacilityApiRoutes {
	Root = 'facility',
	MapFacility = 'map',
	CheckDuplicatesByHierarchyLevel3 = 'duplicates/check-by-hierarchy3',
	CheckMappingDuplicates = 'duplicates/check-mapping'
}

export enum NcpdpApiRoutes {
	Root = 'ncpdp',
	Location = 'location',
	CheckIsNcpdpOpenDateBeforeLocationEarliestDate = 'check-ncpdp-opendate-earliestdate'
}

export enum Status340BApiRoutes {
	Root = 'status340b',
	Location = 'location',
	historyExists = 'history-exists'
}

export enum NpiApiRoutes {
	Root = 'npi'
}

export enum PayableApiRoutes {
	Root = 'payable',
	Approve = 'approve',
	PutUnderDevelopment = 'put-under-development'
}

export enum ProfileRoutes {
	Root = 'profile',
	Info = 'info',
	Notifications = 'notifications',
	PasswordResetRequired = 'password-reset-required'
}

export enum AuthRoutes {
	Root = 'auth',
	Challenge = 'challenge',
	Resend = 'resend',
	Token = 'token',
	VerifyCode = 'verify-code',
	RefreshToken = 'refresh-token'
}

export enum PasswordRoutes {
	Root = 'password',
	SendAuthenticatedUserPasswordResetVerifyCode = 'send-authenticated-user-password-reset-verify-code',
	SendForgotPasswordEmail = 'send-forgot-password-email',
	SendPasswordResetVerifyCode = 'send-password-reset-verify-code',
	ConfirmAuthenticatedUserPasswordReset = 'confirm-authenticated-user-password-reset',
	ConfirmPasswordReset = 'confirm-password-reset',
	PasswordChangeRequest = 'password-change-request',
	CurrentUserPasswordChangeRequest = 'current-user-password-change-request'
}

export enum RebateReceivableApiRoutes {
	Root = 'rebate-receivable',
	Lock = 'lock',
	PutUnderDevelopment = 'put-under-development',
	GroupedSearch = 'grouped-search'
}

export enum RejectsLoadApiRoutes {
	Root = 'rejects-load',
	Upload = 'upload',
	Columns = 'columns',
	Process = 'process',
	Templates = 'templates',
	Years = 'years',
	GetFilesStatuses = 'statuses',
	Statistic = 'statistic',
	StatisticPeriod = 'period'
}

export enum RebateAggregatorApiRoutes {
	Root = 'rebate-aggregator',
	Rate = 'ndc-rates',
	CheckNdcFormularyDuplicates = 'duplicates/check-ndc-formulary',
	CheckNdcFormularyEffectiveDateOverlaping = 'check-ndc-formulary-effective-date-overlapping',
	AddRate = 'add-rate',
	SearchRebateAggregatorNdcLists = 'search-rebate-aggregator-ndc-lists',
	CheckDuplicates = 'duplicates/check-by-effective-date',
	Formulary = 'formulary',
	Aggregator = 'aggregator',
	Ndc = 'ndc'
}

export enum ReportApiRoutes {
	Root = 'report',
	DownloadFile = 'file',
	Generate = 'generate'
}

export enum PayorsApiRoutes {
	Root = 'payor',
	Customer = 'customer',
	Review = 'review',
	ReviewFiltered = 'review-filtered',
	Indicator = 'indicator'
}

export enum MlApiRoutes {
	Root = 'ml',
	RebatesLoad = 'rebate-load',
	Conversion = 'conversion',
	Mapping = 'mapping'
}

export enum SubmissionPeriodApiRoutes {
	Root = 'submission-period',
	Active = 'active',
	Recent = 'recent',
	Status = 'status',
	CheckByDates = 'check-by-dates'
}

export enum LineOfBusinessApiRoutes {
	Root = 'lob'
}

export enum LineOfBusinessSubtypeApiRoutes {
	Root = 'lob/subtype'
}

export enum BdcApiRoutes {
	Root = 'bdc'
}

export enum CustomerBdcApiRoutes {
	Root = 'customer/bdc'
}

export enum CustomerCustomDateOfServiceApiRoutes {
	Root = 'customer/custom-dos',
	CheckDuplicates = 'duplicates/check-by-id'
}

export enum NdcReplacementMappingApiRoutes {
	Root = 'ndc/replacement',
	CheckByDates = 'check-by-dates'
}

export enum DownstreamClientIdAssociationApiRoutes {
	Root = 'downstream-client-id-association',
	CheckByDates = 'check-by-dates'
}

export enum AberrantQuantityApiRoutes {
	Root = 'aberrant-quantity',
	CheckDuplicates = 'duplicates/check-by-ndc'
}

export enum UnbreakableQuantityApiRoutes {
	Root = 'unbreakable-quantity',
	CheckDuplicates = 'duplicates/check-by-ndc',
	Medispan = 'medispan'
}

export enum RebateGroupApiRoutes {
	Root = 'rebate-group'
}

export enum ExcludedPharmacyApiRoutes {
	Root = 'excluded-pharmacy',
	Options = 'options',
	CheckDuplicates = 'duplicates/check-by-ncpdp',
	DataQ = 'dataq'
}

export enum RebateGroupFormularyApiRoutes {
	Root = 'rebate-group/formulary'
}

export enum RebateGroupRulesApiRoutes {
	Root = 'rebate-group/rule'
}

export enum InOutpatientDefinitionApiRoutes {
	Root = 'in-outpatient-definition',
	CustomerDefinitions = 'customer-definitions'
}
