export enum TwrxErrorCode {
	UnhandledError = 1,
	FileIsTooLarge = 2,
	BadRequest = 3,
	InvalidFileType = 4,
	FileExpired = 5,
	FileNotFound = 6,
	InvalidUsernameOrPassword = 101,
	MfaInvalidCode = 102,
	UnknownAuthError = 103,
	UserIsDisabled = 104,
	MfaCodeExpired = 105,
	LinkExpired = 106,
	AuthenticationRequired = 107,
	CodeResendExpired = 108,
	AccessCodeExpired = 109,
	PasswordContainPersonalInformation = 110,
	WeakPasswordPattern = 111,
	MaxInvalidLoginAttempts = 112,
	PasswordReuse = 113,
	PasswordResetRequired = 114,
	InvalidApplicationAccessKey = 115,
	PasswordAlreadyChangedRecently = 201,
	UserAlreadyExists = 202,
	UserNotFound = 203,
	RoleNotFound = 401,
	CustomerNotFound = 501,
	CustomerRetentionRateNotFound = 502,
	CustomerRetentionRateRageInterception = 503,
	CustomerNoteNotFound = 504,
	CustomerCustomDateOfServiceNotFound = 505,
	CustomerDateOfServiceSubmissionPeriodAlreadyInUse = 506,
	RebatesLoadFileNotFound = 1101,
	RebatesLoadTemplateNotFound = 1102,
	RebatesLoadFileStatisticNotFound = 1103,
	RebatesLoadSheetNotFound = 1104,
	InvalidQuantityName = 1105,
	RebateLoadConversionTypeNotFound = 1106,
	RebateRecordNotFound = 1107,
	DontHavePermissionToDeleteFile = 1108,
	CantDeleteSubmittedFile = 1109,
	InvalidFileDuplicateJson = 1110,
	CantDeleteLastDuplicateInGroup = 1111,
	RebatesLoadFileMappingNotFound = 1112,
	RebatesLoadFileAlreadyLoaded = 1113,
	NotValidSubmissionPeriodStatus = 1114,
	RebateAggregatorNotFound = 1201,
	RebateAggregatorNdcEffectiveDateInvalid = 1202,
	RebateAggregatorNameAlreadyExist = 1204,
	RebateNotFound = 1301,
	ReportNotFound = 1401,
	RejectsLoadFileNotFound = 1501,
	RejectsLoadTemplateNotFound = 1502,
	RejectsLoadFileStatisticNotFound = 1503,
	RejectsLoadSheetNotFound = 1504,
	UserDoesntHaveTenants = 2001,
	ClaimFileNotFound = 2002,
	ClaimFileDeleted = 2003,
	InvalidIngredientsCount = 2004,
	FacilityNotFound = 2101,
	FacilityMappingNotFound = 2102,
	NoFacilitesAtImportedFile = 2103,
	LocationImportNameColumnNotExist = 2104,
	LocationImportTypeColumnNotExist = 2105,
	LocationImportEffectiveDateColumnNotExist = 2106,
	LocationImportHierarchy3ColumnNotExist = 2107,
	SubmissionPeriodStartColumnNotExist = 2108,
	FacilityMappingAlreadyMapped = 2109,
	FacilityMappingCannotBeDeleted = 2110,
	FacilityMappingToFileNotFound = 2111,
	LocationCannotBeDeleted = 2112,
	LineOfBusinessNotFound = 2201,
	LineOfBusinessNameAlreadyExist = 2202,
	LineOfBusinessSubtypeNotFound = 2301,
	BdcNotFound = 2401,
	CustomerBdcNotFound = 2501,
	NdcReplacementMappingNotFound = 2601,
	NdcReplacementMappingAlreadyExistsForPeriodsRange = 2602,
	NdcReplacementMappingInvalidPeriodsRange = 2603,
	DownstreamClientIdAssociationNotFound = 2701,
	SubmissionPeriodNotFound = 2801,
	SubmissionPeriodDatesOverlap = 2802,
	CannotDeleteSubmissionPeriodStatistic = 2861,
	CannotDeleteSubmissionPeriodRebateLoad = 2862,
	CannotDeleteSubmissionPeriodCustomer = 2863,
	CannotDeleteSubmissionPeriodLocation = 2864,
	CannotDeleteSubmissionPeriodEligibleNdc = 2865,
	CannotDeleteSubmissionPeriodNdcReplacement = 2866,
	CannotDeleteSubmissionPeriodCustomerBdc = 2867,
	RebateAggregatorNdcRateNotFound = 2901,
	RebateAggregatorNdcRateNdcColumnNotExist = 2902,
	RebateAggregatorNdcRateColumnNotExist = 2903,
	RebateAggregatorNdcRateFillDateStartColumnNotExist = 2904,
	RebateAggregatorNdcRateEffectiveDateStartColumnNotExist = 2905,
	RebateAggregatorNdcRateAggregatorColumnNotExist = 2906,
	RebateAggregatorNdcRateAggregatorFormularyColumnNotExist = 2907,
	AberrantQuantityNotFound = 3001,
	AberrantQuantityNdcAlreadyExist = 3002,
	UnbreakableQuantityNotFound = 3101,
	UnbreakableQuantityNdcAlreadyExist = 3102,
	PharmacyProviderNcpdpNotFound = 3201,
	RebateGroupNotFound = 3301,
	RebateGroupNameAlreadyExist = 3302,
	RebateGroupFormularyNotFound = 3303,
	RebateGroupFormularyAlreadyExist = 3304,
	RebateGroupRuleNotFound = 3305,
	RebateGroupRuleAlreadyExist = 3306,
	ExternalApplicationAlreadyExists = 3401,
	ExternalApplicationNotFound = 3402,
	ExcludedPharmacyNotFound = 3501,
	ExcludedPharmacyNcpdpAlreadyExist = 3502,
	RebateAggregatorFormularyNotFound = 3601,
	RebateAggregatorFormularyNameAlreadyExist = 3602,
	FormularyNdcNotFound = 3701,
	FormularyNdcColumnNotExist = 3702,
	FormularyNdcFillDateStartColumnNotExist = 3703,
	FormularyNdcEffectiveDateStartColumnNotExist = 3704,
	FormularyNdcAggregatorColumnNotExist = 3705,
	FormularyNdcAggregatorFormularyColumnNotExist = 3706,
	FormularyNdcAlreadyExist = 3707,
	InOutpatientDefinitionNotFound = 3801
}
