import { Injectable } from '@angular/core';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { environment } from 'environments/environment';

@Injectable()
export class DatadogRumService {
	private readonly config: RumInitConfiguration = {
		applicationId:           environment.datadog.applicationId,
		clientToken:             environment.datadog.clientToken,
		site:                    environment.datadog.site,
		service:                 environment.datadog.service,
		env:                     environment.name,
		sessionSampleRate:       100,
		sessionReplaySampleRate: 20,
		trackUserInteractions:   true,
		trackResources:          true,
		trackLongTasks:          true,
		defaultPrivacyLevel:     'mask-user-input'
	};

	constructor() {}

	public init(): void {
		if (!environment.datadog.useDatadogRum) {
			return;
		}

		datadogRum.init(this.config);
	}
}
