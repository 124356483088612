import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmptyOrWhiteSpace } from 'app/common/utils/utils.string';
import {
	selectAccountStatus,
	selectTokens
} from 'app/store/account/account.selectors';
import { AuthStatus, IAccountState } from 'app/store/account/account.state';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, of, switchMap, take } from 'rxjs';
import { AuthRoutes } from 'app/services/api/api-routes';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private readonly refreshTokenSubject: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private status: AuthStatus;

	constructor(private readonly store: Store<IAccountState>) {
		this.store.select(selectAccountStatus).subscribe(status => {
			this.status = status;

			if (
				status === AuthStatus.Authenticated
				|| status === AuthStatus.SignedOut
			) {
				this.refreshTokenSubject.next(true);
			}
		});
	}

	public intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		let resultRequest$ = of(request);

		if (request.url.startsWith(environment.apiUrl)) {
			resultRequest$ = resultRequest$.pipe(
				map(req => this.applyWithCredentials(req)),
				switchMap(req =>
					this.store.select(selectTokens).pipe(
						take(1),
						map(([token, refreshToken]) =>
							this.applyTokens(req, token, refreshToken)
						)
					)
				)
			);
		}

		return resultRequest$.pipe(switchMap(req => next.handle(req)));
	}

	private applyWithCredentials(
		request: HttpRequest<unknown>
	): HttpRequest<unknown> {
		return request.clone({
			setHeaders: {
				'X-Requested-With': 'XMLHttpRequest'
			},
			withCredentials: true
		});
	}

	private applyTokens(
		request: HttpRequest<unknown>,
		accessToken: string,
		refreshToken: string
	): HttpRequest<unknown> {
		if (
			isEmptyOrWhiteSpace(accessToken)
			|| isEmptyOrWhiteSpace(refreshToken)
		) {
			return request;
		}

		const token = request.url.endsWith(AuthRoutes.RefreshToken)
			? refreshToken
			: accessToken;

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
	}
}
