import { AsyncPipe, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { notificationUpdatePeriod } from 'app/common/app-constants';
import { BaseApiComponent } from 'app/common/base/base-api-component';
import { AccountRoutes } from 'app/common/routes';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import {
	loadCurrentUserInfo,
	loadCurrentUserNotifications
} from 'app/store/account/account.actions';
import {
	selectCurrentUser,
	selectNotifications
} from 'app/store/account/account.selectors';
import { IAccountState } from 'app/store/account/account.state';
import { signOut } from 'app/store/auth-proxy/auth-proxy.actions';
import {
	distinctUntilChanged,
	interval,
	map,
	Observable,
	startWith
} from 'rxjs';

import { NotificationActionSelectorComponent } from '../notification-action-selector/notification-action-selector.component';

@UntilDestroy()
@Component({
	selector:        'app-profile',
	templateUrl:     './profile.component.html',
	encapsulation:   ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default,
	exportAs:        'user',
	standalone:      true,
	imports:         [
		MatButtonModule,
		MatIconModule,
		MatBadgeModule,
		MatMenuModule,
		NgIf,
		MatListModule,
		RouterLink,
		TranslocoModule,
		AsyncPipe
	]
})
export class ProfileComponent
	extends BaseApiComponent
	implements OnInit, OnDestroy
{
	@Input() showAvatar: boolean = true;

	public userEmail: Observable<string>;
	public notificationCount: number = 0;
	public accountRoutes = AccountRoutes;

	private notificationDialogRef: MatDialogRef<NotificationActionSelectorComponent>;

	constructor(
		private readonly store: Store<IAccountState>,
		private readonly matDialog: MatDialog
	) {
		super();
	}

	public get hideNotificationCount(): boolean {
		return this.notificationCount <= 0;
	}

	ngOnInit(): void {
		this.store.dispatch(
			loadCurrentUserInfo(this.createRequestPayload(null))
		);

		interval(notificationUpdatePeriod)
			.pipe(startWith(0), untilDestroyed(this))
			.subscribe(() => {
				this.store.dispatch(loadCurrentUserNotifications());
			});

		this.store
			.select(selectNotifications)
			.pipe(
				untilDestroyed(this),
				filterNullOrUndefined(),
				map(x => x.payorsUnreadCount)
			)
			.subscribe(payorCount => {
				this.notificationCount = payorCount;
			});

		this.userEmail = this.store.select(selectCurrentUser).pipe(
			filterNullOrUndefined(),
			map(({ email }) => email),
			distinctUntilChanged()
		);
	}

	ngOnDestroy(): void {
		this.notificationDialogRef?.close();
		super.ngOnDestroy();
	}

	public openNotifications(): void {
		if (this.hideNotificationCount) {
			return;
		}

		this.notificationDialogRef = this.matDialog.open(
			NotificationActionSelectorComponent,
			{
				data: this.notificationCount
			}
		);
	}

	public signOut(): void {
		this.store.dispatch(signOut());
	}
}
