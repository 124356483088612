<div class="flex flex-row items-center gap-x-2">
	<!-- Notifications button -->
	<button
		mat-icon-button
		(click)="openNotifications()"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-bell"
			class="relative text-xl"
			matBadge="{{ notificationCount }}"
			[matBadgeHidden]="hideNotificationCount"
			matBadgeColor="primary"
		></mat-icon>
	</button>

	<!-- User menu button -->
	<button
		mat-icon-button
		[matMenuTriggerFor]="userActions"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-user"
			class="relative text-xl"
		></mat-icon>
	</button>
</div>

<!-- User menu -->
<mat-menu
	xPosition="before"
	#userActions="matMenu"
	class="min-w-64 rounded-xl border shadow-2xl"
>
	<span class="flex flex-col px-4 pt-4 leading-none">
		<span class="text text-secondary">Signed in as</span>
		<span class="overflow-x-auto whitespace-nowrap pb-1 pt-2 text-lg font-medium">{{ userEmail | async }}</span>
	</span>
	<mat-divider class="my-1"></mat-divider>
	<button
		mat-menu-item
		[routerLink]="accountRoutes.Profile"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-user"
			class="fa-lg"
		></mat-icon>
		<span>{{ 'nav.profile' | transloco }}</span>
	</button>
	<mat-divider class="my-1"></mat-divider>
	<button
		class="mb-1"
		mat-menu-item
		(click)="signOut()"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-sign-out-alt"
			class="fa-lg"
		></mat-icon>
		<span>{{ 'nav.signOut' | transloco }}</span>
	</button>
</mat-menu>
