import { createAction, props } from '@ngrx/store';
import { CustomerInOutpatientDefinitionModel } from 'app/models/api/customer-in-outpatient-definition-model';
import { CustomerInOutpatientDefinitionSearchFilterModel } from 'app/models/api/customer-in-outpatient-definition-search-filter-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { InOutpatientDefinitionModel } from 'app/models/api/in-outpatient-definition-model';
import { InOutpatientDefinitionSearchFilterModel } from 'app/models/api/in-outpatient-definition-search-filter-model';
import { InOutpatientDefinitionUpdateModel } from 'app/models/api/in-outpatient-definition-update-model';
import { SearchResponse } from 'app/models/api/search-response';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum InOutpatientDefinitionActions {
	LoadInOutpatientDefinitions = '[InOutpatientDefinition] LoadInOutpatientDefinitions',
	InOutpatientDefinitionsLoaded = '[InOutpatientDefinition] InOutpatientDefinitionsLoaded',

	PreLoadInOutpatientDefinition = '[InOutpatientDefinition] PreLoadInOutpatientDefinition',
	InOutpatientDefinitionPreLoaded = '[InOutpatientDefinition] InOutpatientDefinitionPreLoaded',
	PreLoadedInOutpatientDefinitionNotFound = '[InOutpatientDefinition] PreLoadedInOutpatientDefinitionNotFound',

	LoadInOutpatientDefinition = '[InOutpatientDefinition] LoadInOutpatientDefinition',
	InOutpatientDefinitionLoaded = '[InOutpatientDefinition] InOutpatientDefinitionLoaded',
	LoadedInOutpatientDefinitionNotFound = '[InOutpatientDefinition] LoadedInOutpatientDefinitionNotFound',

	UpdateInOutpatientDefinition = '[InOutpatientDefinition] UpdateInOutpatientDefinition',
	InOutpatientDefinitionUpdated = '[InOutpatientDefinition] InOutpatientDefinitionUpdated',

	LoadCustomerInOutpatientDefinitions = '[InOutpatientDefinition] LoadCustomerInOutpatientDefinitions',
	CustomerInOutpatientDefinitionsLoaded = '[InOutpatientDefinition] CustomerInOutpatientDefinitionsLoaded',

	ErrorOccurred = '[InOutpatientDefinition] ErrorOccurred'
}

export const loadInOutpatientDefinitions = createAction(
	InOutpatientDefinitionActions.LoadInOutpatientDefinitions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<InOutpatientDefinitionSearchFilterModel>
		>
	>()
);

export const inOutpatientDefinitionsLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionsLoaded,
	props<ActionResponsePayload<SearchResponse<InOutpatientDefinitionModel>>>()
);

export const preLoadInOutpatientDefinition = createAction(
	InOutpatientDefinitionActions.PreLoadInOutpatientDefinition,
	props<ActionRequestPayload<number>>()
);

export const inOutpatientDefinitionPreLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionPreLoaded,
	props<ActionResponsePayload<InOutpatientDefinitionModel>>()
);

export const loadInOutpatientDefinition = createAction(
	InOutpatientDefinitionActions.LoadInOutpatientDefinition,
	props<ActionRequestPayload<number>>()
);

export const inOutpatientDefinitionLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionLoaded,
	props<ActionResponsePayload<InOutpatientDefinitionModel>>()
);

export const loadedInOutpatientDefinitionNotFound = createAction(
	InOutpatientDefinitionActions.LoadedInOutpatientDefinitionNotFound
);

export const updateInOutpatientDefinition = createAction(
	InOutpatientDefinitionActions.UpdateInOutpatientDefinition,
	props<ActionRequestPayload<InOutpatientDefinitionUpdateModel>>()
);

export const loadCustomerInOutpatientDefinitions = createAction(
	InOutpatientDefinitionActions.LoadCustomerInOutpatientDefinitions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<CustomerInOutpatientDefinitionSearchFilterModel>
		>
	>()
);

export const customerInOutpatientDefinitionsLoaded = createAction(
	InOutpatientDefinitionActions.CustomerInOutpatientDefinitionsLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<CustomerInOutpatientDefinitionModel>
		>
	>()
);

export const inOutpatientDefinitionUpdated = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionUpdated
);

export const errorOccurred = createAction(
	InOutpatientDefinitionActions.ErrorOccurred
);
